import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Add this before Sentry.init
const originalConsoleLog = console.log;
const originalConsoleError = console.error;

// Preserve console.log even in production
console.log = (...args) => {
  originalConsoleLog(`LOG: `, ...args);
};

console.error = (...args) => {
  originalConsoleError(` ERROR: `, ...args);
};

Sentry.init({
  dsn: "https://b4b8caa7a1ceac61cc5cc98b761840d1@o4506785322696704.ingest.sentry.io/4506793184591872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: (event, hint) => {
    if (window.location.hostname === "portal.themaxion.com") {
      return null;
    }

    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.themaxion\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
